import { DefaultLayout } from '@/layouts/default-layout/DefaultLayout';
import MaskIcon from '@images/resources/mask-icon.png';
import GunIcon from '@images/resources/gun-icon.png';
import GuyImage from '@images/resources/guy.png';
import '../styles/global.scss';
import '../styles/resources-page.scss';
import { configs } from '@/constants/configs';

const links: ResourceLinkProps[] = [
	{ label: 'White paper', url: '', icon: MaskIcon },
	{ label: 'Pitch deck', url: '', icon: GunIcon },
];

const pageName: string = 'Resources';

export default function Resources() {
	return (
		<DefaultLayout className="resources-page" pageTitle={`${configs.defaultPageTitle} - ${pageName}`}>
			<h2 className="resources-page__title atmospheric-text">{pageName}</h2>
			<ul className="resources-page__list">
				{links.map((item: ResourceLinkProps, index: number) => (
					<ResourceLink {...item} key={index} />
				))}
			</ul>
      <div className="resources-page__decorative-img" aria-hidden="true">
        <img src={GuyImage} />
      </div>
		</DefaultLayout>
	);
}

type ResourceLinkProps = {
	icon: string;
	label: string;
	url: string;
};

const ResourceLink = ({ icon, label, url }: ResourceLinkProps) => {
	return (
		<li className="resources-page__list-item">
			<div className="icon-wrapper">
				<img src={icon} alt={label} />
			</div>
			<a href={url} target="_blank" className="link atmospheric-text">
				{label}
			</a>
		</li>
	);
};
